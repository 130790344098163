<template>
  <div>
    <p>
      We have sent you a one time verification code to your associated e-mail
      address.
    </p>

    <p>Please enter it below to continue:</p>
    <div class="element">
      <input required v-model="code" type="text" placeholder="One time code" />
    </div>

    <b-button variant="success" @click="submitCode">Verify</b-button>

    <p class="non-received">
      If you haven't received your code, please
      <span class="resend" @click="resendCode">click here</span>
    </p>

    <hr />

    <p class="back-link" @click="returnToLogin">Back to login</p>
  </div>
</template>

<script>
import { recaptcha } from "@/components/mixins/recaptcha";

export default {
  name: "LoginMFACode",
  props: {
    authToken: {
      type: String
    }
  },
  mixins: [recaptcha],
  data() {
    return {
      code: ""
    };
  },
  methods: {
    returnToLogin() {
      this.code = "";
      this.$emit("reset");
    },
    async submitCode() {
      if (!this.code) {
        this.$swal({
          type: "error",
          title: "Invalid code",
          text: "Please enter the code sent via e-mail"
        });
        return;
      }

      const title = "Verifying code";
      const subtitle = "Please wait a moment";
      await this.$store.dispatch("loaderInitiate", { title, subtitle });

      const token = await this.recaptchaToken();

      this.$store
        .dispatch("loginMFACode", {
          authToken: this.authToken,
          code: this.code,
          token
        })
        .then(response => {
          if (response.state && response.state === "mfa_expired") {
            const title = "Login expired";
            const subtitle =
              "Your request has expired, please restart the login process";
            this.$store.dispatch("pushError", { title, subtitle });
            this.$store.dispatch("loaderCancel");
            this.returnToLogin();
            return;
          }

          this.$emit("success");
        })
        .catch(err => {
          this.$store.dispatch("loaderCancel");

          this.$swal({
            type: "error",
            title: "Oops...",
            text: err
          });
        })
        .finally(() => {});
    },
    async resendCode() {
      const title = "Resending code";
      const subtitle = "Please wait a moment";
      await this.$store.dispatch("loaderInitiate", { title, subtitle });

      const token = await this.recaptchaToken();

      this.$store
        .dispatch("loginMFACodeResend", { authToken: this.authToken, token })
        .then(() => {
          this.$store.dispatch("loaderCancel");
          const title = "Code Resent";
          const subtitle = "Please check your e-mail for the verification code";
          const samePage = true;
          this.$store.dispatch("pushSuccess", { title, subtitle, samePage });
        })
        .catch(() => {
          const title = "Login expired";
          const subtitle =
            "Your request has expired, please restart the login process";
          this.$store.dispatch("pushError", { title, subtitle });
          this.$store.dispatch("loaderCancel");
          this.returnToLogin();
        })
        .finally(() => {});
    }
  }
};
</script>

<style lang="scss" scoped>
h3 {
  font-size: 1.1rem;
}

.login {
  width: 450px;
}

.sub-detail {
  margin-top: 20px;
}

p {
  font-size: 0.9rem;
}

.integration-logo {
  padding: 15px 0px;
  img {
    width: 80%;
    max-width: 200px;
  }
}

.back-link {
  cursor: pointer;
}

.non-received {
  margin: 15px 0px;
  font-size: 0.8rem;
}

.resend {
  cursor: pointer;
}
</style>
