export const recaptcha = {
  methods: {
    recaptchaToken() {
      return new Promise(resolve => {
        // eslint-disable-next-line no-undef
        grecaptcha.ready(async () => {
          // eslint-disable-next-line no-undef
          const token = await grecaptcha.execute(this.siteKeyV3);
          resolve(token);
        });
      });
    }
  },
  computed: {
    siteKeyV3() {
      return process.env.VUE_APP_RECAPTCHA_KEY_V3;
    }
  }
};
